<template>
  <div class="default">
    <NuxtLoadingIndicator />
    <MainNavbar />
    <div class="content">
      <ClientOnly>
        <NuxtPage />
      </ClientOnly>
    </div>
    <MainFooter class="footer" />
  </div>
</template>

<script setup></script>

<style lang="less" scoped>
.default {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .content {
    width: 100%;
    margin-top: 80px;
    min-height: calc(100vh - 440px);
  }
}
</style>
